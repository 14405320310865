/* SLIDE UP */
const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = `height, margin, padding`
  target.style.transitionDuration = duration + `ms`
  target.style.boxSizing = `border-box`
  target.style.height = target.offsetHeight + `px`
  target.offsetHeight
  target.style.overflow = `hidden`
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  window.setTimeout(() => {
    target.style.display = `none`
    target.style.removeProperty(`height`)
    target.style.removeProperty(`padding-top`)
    target.style.removeProperty(`padding-bottom`)
    target.style.removeProperty(`margin-top`)
    target.style.removeProperty(`margin-bottom`)
    target.style.removeProperty(`overflow`)
    target.style.removeProperty(`transition-duration`)
    target.style.removeProperty(`transition-property`)
    // alert("!")
  }, duration)
}

/* SLIDE DOWN */
const slideDown = (target, duration = 500) => {
  target.style.removeProperty(`display`)
  let display = window.getComputedStyle(target).display
  if (display === `none`) {
    display = `block`
  }
  target.style.display = display
  const height = target.offsetHeight
  target.style.overflow = `hidden`
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.offsetHeight
  target.style.boxSizing = `border-box`
  target.style.transitionProperty = `height, margin, padding`
  target.style.transitionDuration = duration + `ms`
  target.style.height = height + `px`
  target.style.removeProperty(`padding-top`)
  target.style.removeProperty(`padding-bottom`)
  target.style.removeProperty(`margin-top`)
  target.style.removeProperty(`margin-bottom`)
  window.setTimeout(() => {
    target.style.removeProperty(`height`)
    target.style.removeProperty(`overflow`)
    target.style.removeProperty(`transition-duration`)
    target.style.removeProperty(`transition-property`)
  }, duration)
}

/* TOOGGLE */
const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === `none`) {
    return slideDown(target, duration)
  } else {
    return slideUp(target, duration)
  }
}

(function ($) {
  $(document).ready(function () {
    svg4everybody({})
  })
})(jQuery)

// set vh height
window.addEventListener("resize",function(){
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
})
// set vh height end

window.addEventListener("load",function(){
  let body = this.document.querySelector("body");
  let html = this.document.querySelector("html");

  body.style.opacity = 1

  // set vh height
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // set vh height end

  // aos  ===================================================
  setTimeout(function(){
    AOS.init({
      duration: 1000,
      once: true,
    });
  },10)
  // aos end  ===================================================


  // SELECT ===================================================
  function closeSelect() {
    selects.forEach(item => {
      if (item.classList.contains(('active'))) {
        item.classList.remove('active')
      }
    })
    dropDowns.forEach(item => {
      if (item.classList.contains(('active'))) {
        item.classList.remove('active')
        slideUp(item, 100)
      }
    })
  }
  const selectsInside = document.querySelector('.selects-inside')
  const selectTrigger = document.querySelectorAll('.select__trigger')
  const selects = document.querySelectorAll('.selecter')
  const dropDowns = document.querySelectorAll('.select__dropdown')
  const dropDownOption = document.querySelectorAll('.select__option')
  selectTrigger && selectTrigger.forEach(item => {
    item.addEventListener('click', function () {
      const container = this.closest('.selecter')
      const dropdown = container.querySelector('.select__dropdown')
      const selectsItems = [...selects].filter(item => item !== container)
      const dropDownsItems = [...dropDowns].filter(item => item !== dropdown)
      selectsItems.forEach(item => {
        item.classList.remove('active')
      })
      dropDownsItems.forEach(item => {
        item.classList.remove('active')
        slideUp(item, 100)
      })
      // closeSelect()
      if (!container.classList.contains('active')) {
        container.classList.add('active')
        dropdown && dropdown.classList.add('active')
        slideDown(dropdown, 100)
      } else {
        container.classList.remove('active')
        dropdown && dropdown.classList.remove('active')
        slideUp(dropdown, 100)
      }
    })
  })

  selectsInside && selectsInside.addEventListener('click', function (e) {
    const target = e.target
    if (!target.closest('.selecter')) {
      closeSelect()
    }
  })

  dropDownOption && dropDownOption.forEach(item => {
    item.addEventListener('click', function () {
      const container = this.closest('.selecter')
      const valueItem = container.querySelector('.select__value')
      const input = container.querySelector('.select__input')
      const options = container.querySelectorAll('.select__option')
      const formItem = this.closest('.form-item')
      const trigger = container.querySelector('.select__trigger')

      if (trigger.classList.contains('placeholder')) {
        trigger.classList.remove('placeholder')
      }

      if (formItem) {
        if (formItem.classList.contains('error')) {
          formItem.classList.remove('error')
        }
      }
      const value = this.getAttribute('data-value')
      if (valueItem) {
        valueItem.innerHTML = value
      }
      input.value = value
      options.forEach(item => {
        item.classList.remove('active')
      })
      this.classList.add('active')
      closeSelect()
    })
  })
  // END SELECT ===================================================

  // header menu ======================================================
  let headerMenu = document.querySelector(".header-mobile__menu")
  let headerBurger = document.querySelector(".header__burger")
  let overlay = document.querySelector(".overlay")
  if(headerMenu && headerBurger){
    let closeMenu = function(){
      headerMenu.classList.remove("active")
      headerBurger.classList.remove("active")
      body.classList.remove("lock")
      html.classList.remove("lock")
      if(overlay){
        overlay.classList.remove("active")
      }
    }

    headerBurger.addEventListener('click',function(){
      headerMenu.classList.toggle("active")
      headerBurger.classList.toggle("active")
      body.classList.toggle("lock")
      html.classList.toggle("lock")
      if(overlay){
        overlay.classList.toggle("active")
      }
    })

    // anchor
    let anchorTrigger = document.querySelectorAll(".anchor-trigger")
    let anchorBlock = document.querySelectorAll(".anchor-block")
    let header = document.querySelector(".header")
    if(anchorBlock && anchorTrigger && header){
      anchorTrigger.forEach(trigger=>{
        trigger.addEventListener("click",function(e){
          e.preventDefault()
          anchorBlock.forEach(b=>{
            if(trigger.classList.contains("header__logo") || trigger.classList.contains("footer__logo")){
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              })
            }else if(trigger.getAttribute("data-anchor").toLowerCase() == b.getAttribute("data-block").toLowerCase()){
              let boundingBlock = b.offsetTop
              window.scrollTo({
                top: boundingBlock - header.offsetHeight,
                behavior: "smooth"
              })
              if(headerMenu.classList.contains("active")){
                closeMenu()
              }
            }

          })

        })
      })

      // online check block
      let onlineCheck = function(){
        anchorBlock.forEach(scrollBlock=>{
          let topOffset = scrollBlock.offsetTop - (header.offsetHeight + 20)
          let allHeight = (scrollBlock.offsetTop + scrollBlock.offsetHeight) - (header.offsetHeight + 20)
          let stepId  = scrollBlock.getAttribute("data-block")
          let activeNav = document.querySelector(`.anchor-trigger[data-anchor=${stepId}]`)
          if(window.scrollY > topOffset && window.scrollY <= allHeight){
            if(activeNav){
              activeNav.classList.add('active')
            }
          }else{
            if(activeNav){
              activeNav.classList.remove('active')
            }
          }
        })
      }
      window.addEventListener("scroll",onlineCheck)
      onlineCheck()
      // online check block end
    }
    // anchor end

    window.addEventListener("resize",function(){
      if(window.innerWidth > 1024 && headerMenu.classList.contains("active")){
        closeMenu()
      }
    })
  }
  // header menu end ======================================================

  // slick text  ======================================================
  function handleMarquee(){
    const marquee = document.querySelectorAll('.global-slick-container');
    if(marquee){
      let speed = 2;
      let lastScrollPos = 0;
      let timer;

      marquee.forEach(function(el){
        const container = el.querySelector('.global-slick-text');
        const content = el.querySelector('.global-slick-text > *');
        //Get total width
        const  elWidth = content.offsetWidth;


        //Duplicate content
        let clone = content.cloneNode(true);
        container.appendChild(clone);

        let progress = 1;
        function loop(){
          progress = progress-speed;
          if(progress <= (elWidth + 40)*-1) {progress=0;}
          container.style.transform = 'translateX(' + progress + 'px)';
          // container.style.transform += 'skewX(' + speed*0.4 + 'deg)';
          window.requestAnimationFrame(loop);
        }
        loop();
      });
      // window.addEventListener('scroll', function(){
      //   const maxScrollValue = 12;
      //   const newScrollPos = window.scrollY;
      //   let scrollValue = newScrollPos - lastScrollPos;
      //   if( scrollValue > maxScrollValue ) scrollValue = maxScrollValue;
      //   else if( scrollValue < -maxScrollValue ) scrollValue = -maxScrollValue;
      //   speed = scrollValue;
      //   clearTimeout(timer);
      //   timer = setTimeout(handleSpeedClear, 10);
      // });
      function handleSpeedClear(){
        speed = 2;
      }
    }
  };
  handleMarquee();
  // window.addEventListener('resize',function(){
  //   if(window.innerWidth > 1300){
  //     handleMarquee();
  //   }
  // })
  // slick text end ======================================================

  // portfolio slider  ===================================================
  let portfolioSiders = document.querySelectorAll(".portfolio__item .portfolio-slider")
  if(portfolioSiders){
    portfolioSiders.forEach(slider=>{
      var swiperPortfolio = new Swiper(slider, {
        slidesPerView: 1,
        speed: 800,
        loop: true,
        spaceBetween: 0,
        autoplay:{
          delay:5000
        },
        breakpoints:{
          701: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1025: {
            slidesPerView: 1,
            spaceBetween: 0,
          }
        }
      });
    })
  }
  // portfolio slider end  ===================================================

  // technologies slider  ===================================================
  var technologiesSider = new Swiper(".technologies__slider .swiper-container", {
    slidesPerView: 1,
    speed: 700,
    spaceBetween: 15,
    navigation: {
      nextEl: ".technologies__slider .global-slider-buttons .slider-button__next",
      prevEl: ".technologies__slider .global-slider-buttons .slider-button__prev",
    },
    breakpoints:{
      451: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      767: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1025: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1201: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
      1501: {
        slidesPerView: 6,
        spaceBetween: 20,
      }
    }
  });
  // technologies slider end  ===================================================

  // testimonials slider  ===================================================
  var testimonialsSider = new Swiper(".testimonials__slider .swiper-container", {
    slidesPerView: 1,
    speed: 700,
    spaceBetween: 15,
    grabCursor: true,
    navigation: {
      nextEl: ".testimonials__slider .global-slider-buttons .slider-button__next",
      prevEl: ".testimonials__slider .global-slider-buttons .slider-button__prev",
    },
    breakpoints:{
      577: {
        slidesPerView: 1.5,
        spaceBetween: 15,
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1025: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1351: {
        slidesPerView: 3,
        spaceBetween: 20,
      }
    }
  });
  // testimonials slider end  ===================================================

  // portfolio load more  ===================================================
  let portfolioLoadTrigger = document.querySelector(".portfolio-loadmore__btn")
  let portfolioHideList = document.querySelector(".portfolio__list-hide")
  if(portfolioHideList && portfolioLoadTrigger){
    portfolioLoadTrigger.addEventListener("click",function(){
      // slideDown(portfolioHideList,300)
      portfolioHideList.classList.add("active")
      portfolioLoadTrigger.classList.add("hide")
      AOS.refresh()
    })
  }
  // portfolio load more end  ===================================================

  // process slider ===================================================
  let sliderProcessBox = document.querySelector(".process__slider .swiper-container")
  let sliderProcessButtons = document.querySelectorAll(".process-tab__btn")
  if(sliderProcessBox){
    let ProcessSider = new Swiper(sliderProcessBox, {
      slidesPerView: 1,
      speed: 700,
      spaceBetween: 15,
      allowTouchMove: false,
      autoHeight: true,
    });
    if(sliderProcessButtons){
      sliderProcessButtons.forEach(processBtn=>{
        processBtn.addEventListener("click",function(){
          if(processBtn.hasAttribute("data-slide")){
            let indexSlide = parseInt(processBtn.getAttribute("data-slide"))
            ProcessSider.allowTouchMove = true
            ProcessSider.slideTo(indexSlide)
            ProcessSider.allowTouchMove = false
            let activeProcessBtn = document.querySelector(".process-tab__btn.active")
            if(activeProcessBtn){activeProcessBtn.classList.remove("active")}
            // change photo
            let activePhotoSlide = document.querySelector(".process-tab__photolist img.active")
            if(activePhotoSlide){activePhotoSlide.classList.remove('active')}
            let currentPhoto = document.querySelector(`.process-tab__photolist img[data-step="${indexSlide}"]`)
            if(currentPhoto){currentPhoto.classList.add("active")}
            // change photo end
            processBtn.classList.add("active")
          }
        })
      })
    }
  }
  // process slider end ===================================================

  // contact map ===================================================
  const mapContainerContact = document.getElementById('contact-map');
  if(mapContainerContact){
    const initMap = () => {
      var mymapContact = L.map('contact-map',{
        center: [46.430867, 23.982387],
        zoom: 5,
        minZoom: 3,
        gestureHandling: true
      })
      let iconSrc = './svg/marker.svg'
      const icon = L.icon({
        iconUrl: iconSrc,
        iconSize: [36, 54],
      })
      L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png').addTo(mymapContact)
      let marker = L.marker([49.430867, 26.982387], { icon: icon }).addTo(mymapContact)
    }
    mapContainerContact && initMap();
  }
  // contact map end ===================================================

  // phone mask  ======================================================
  let maskPhoneFields = document.querySelectorAll(".phone-mask-field")
  if(maskPhoneFields){
    maskPhoneFields.forEach(maskInput=>{
      // maskInput.addEventListener("input",function(e){
      //   e.target.value = e.target.value.replace(/\D/g, '');
      // })
      let phoneMask = IMask(maskInput, {
        mask: '+{38(0}00) 000-00-00'
      });
    })
  }
  // phone mask end  ======================================================

  //validate form ======================================================
  const forms = document.querySelectorAll(".form-container")
  forms && forms.forEach(item => {
    // email patern
    let email = item.querySelector(".mail")
    if (email) {
      let parent = email.closest(".form-item")
      let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
      email.addEventListener("keyup", function (ev) {
        ev.stopPropagation();
        let value = email.value;
        if (value.match(pattern)) {
          parent.classList.remove("error__email")
        } else {
          parent.classList.add("error__email")
        }
        if (value.length < 1) {
          parent.classList.remove("error__email")
        }
      })
    }
    // email patern end
    item.addEventListener('submit', function (e) {
      e.preventDefault();
      let valide = true;
      const inputs = item.querySelectorAll('.form-required');

      // checkbox valid
      let checkbox=item.querySelector(".form__checkbox input")
      let checkboxParent=item.querySelector(".form__checkbox")
      if(checkbox){
        valide= false
        if(checkbox.checked){
          valide = true
          checkboxParent.classList.remove("error")
        }else{
          checkboxParent.classList.add("error")
          valide = false
        }
      }
      // checkbox valid end

      // empty fields valid
      inputs.forEach(element => {
        const parent = element.closest('.form-item');
        if (element.value.trim() === '') {
          parent.classList.add('error');
          valide = false;
        } else {
          parent.classList.remove('error');
        }
      })
       // empty fields valid end

      // email validate
      let emailField = item.querySelector(".mail")
      if(email){
        let emailParent = emailField.closest(".form-item")
        if(emailParent && emailParent.classList.contains("error__email")){
          valide = false;
        }
      }
      // email validate end

      // phone numbers valid
      let phoneInputs = item.querySelectorAll(".phone-mask-field")
      if(phoneInputs){
        phoneInputs.forEach(phoneInput=>{
          let phoneParent = phoneInput.closest(".form-item")
          if(phoneInput.value.length < 18){
            phoneParent.classList.add("error")
            valide = false
          }
        })
      }
      // phone numbers valid end

      if (valide) {
        fetch('mail.php', {
          method: 'POST',
          body: new FormData(item)
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        }).then(function (data) {

        })["catch"](function (error) {
          console.warn(error);
        });

        // clear on sent
        let allInputs = item.querySelectorAll(".form__input")
        if(allInputs){
          allInputs.forEach(el=>{
            el.value = "";
          })
        }
        // clear on sent end

        // clear checkbox
        let checkboxInput = item.querySelectorAll('.form__checkbox input')
        if(checkboxInput){
          checkboxInput.forEach(checkForm=>{
            checkForm.checked = false
          })
        }
        // clear checkbox end

        // clear dropdowns
        let dropdownForm = item.querySelectorAll(".select-info.selecter")
        if(dropdownForm){
          dropdownForm.forEach(dropForm=>{
            let valueHolder = dropForm.querySelector(".select__value")
            let inputDrop = dropForm.querySelector("input")
            let inputOptionsActive = dropForm.querySelectorAll(".select__option.active")
            if(inputOptionsActive){
              inputOptionsActive.forEach(option=>{
                option.classList.remove("active")
              })
            }
            if(valueHolder && dropForm.hasAttribute("data-holder")){valueHolder.innerHTML = dropForm.getAttribute("data-holder")}
            if(inputDrop){inputDrop.value = ''}
          })
        }
        // clear dropdowns end

        // close active popup on sent and add thanks popup
        let activePopup = document.querySelector(".modal-wraper.active")
        let thanksPopup = document.querySelector(".modal-wraper.thanks-popup")
        let errorPopup = document.querySelector(".modal-wraper.error-popup")
        if(activePopup){activePopup.classList.remove("active")}
        if(thanksPopup){
          setTimeout(function(){
            thanksPopup.classList.add("active")
          },100)
        }
        // if(errorPopup){
        //   setTimeout(function(){
        //     errorPopup.classList.add("active")
        //   },100)
        // }
        // close active popup on sent and add thanks popup end

        // slide stepper on start and clear radios
        if(quizeStepper){
          setTimeout(function(){
            quizeStepper.slideTo(0,500)
            let AllstepperQuizeRadio = document.querySelectorAll(".quize-stepper__slider .quize-stepper-radio input")
            if(AllstepperQuizeRadio){
              AllstepperQuizeRadio.forEach(elRadio=>{
                elRadio.checked = false
              })
            }
          },200)
        }
        // slide stepper on start and clear radios

      }
    })
  })
  // clear on input
  const inputsField = document.querySelectorAll('.form-container .form-item');
  inputsField.forEach(field=>{
    let inputField = field.querySelector("input")
    if(inputField){
      inputField.addEventListener("input",function(){
        field.classList.remove("error")
      })
    }
  })
  // clear on input end
  // validate form end  ======================================================

  // modals  ======================================================
  let modalsTrigger = document.querySelectorAll(".modal-trigger__btn")
  let modalsBox = document.querySelectorAll(".modal-wraper")
  if(modalsTrigger && modalsBox){
    modalsTrigger.forEach(trigerModal=>{
      trigerModal.addEventListener("click",function(){
        modalsBox.forEach(modalBox=>{
          if(trigerModal.getAttribute("data-type").toLowerCase() == modalBox.getAttribute("data-modal").toLowerCase()){
            modalBox.classList.add("active")
            body.classList.add("lock")
            html.classList.add("lock")
            let callTargetInput = modalBox.querySelector(".from-target-input")
            if(callTargetInput){
              callTargetInput.value = trigerModal.getAttribute("data-place")
            }
          }
        })
      })
    })
    // close popup
    modalsBox.forEach(box=>{
      let closeModalFunc = function(){
        let headerMenu = document.querySelector(".header-mobile__menu.active")
        if(headerMenu){
          box.classList.remove("active")
        }else{
          body.classList.remove("lock")
          html.classList.remove("lock")
          box.classList.remove("active")
        }

      }
      let closeModal = box.querySelectorAll(".modal-close__trigger")
      if(closeModal){
        closeModal.forEach(btnclose=>{
          btnclose.addEventListener("click", closeModalFunc)

        })
      }
      window.addEventListener("click",function(e){
        if(e.target == box){
          closeModalFunc()
        }
      })
    })
    // close popup end
  }
  // modals end  ======================================================

  // quize stepper ===================================================
  let quizeSliderContainer = document.querySelector(".quize-stepper__slider .swiper-container")
  let quizeStepper = new Swiper(".quize-stepper__slider .swiper-container", {
    slidesPerView: 1,
    speed: 500,
    spaceBetween: 15,
    autoHeight: true,
    allowTouchMove: false,
  });
  let sliderFirstPhoto = this.document.querySelector(".quize-stepper-main__img")
  quizeStepper.on("slideChange",function(){
    if(quizeStepper.activeIndex < 1 && sliderFirstPhoto){
      sliderFirstPhoto.classList.remove("hide")
    }else{
      sliderFirstPhoto.classList.add("hide")
    }
  })
  if(quizeSliderContainer && quizeStepper){
    // validation quize step by step ==================================================================================================
    let validateStep = function(incomeBtn){
      let quizeActiveStep = quizeSliderContainer.querySelector(".swiper-slide.swiper-slide-active")
      if(quizeActiveStep){
        let quizeStepRadios = quizeActiveStep.querySelectorAll(".quize-stepper-radio input")
        let validateStep = false

        // add error if radio !checked
        let radioParent = quizeActiveStep.querySelectorAll(".quize-stepper-radio")
        if(radioParent){
          radioParent.forEach(radioQuize=>{
            radioQuize.classList.add("error-radio")
          })
        }
        // add error if radio !checked end

        // validate current step
        if(quizeStepRadios && quizeStepRadios.length > 0){
          quizeStepRadios.forEach(radio=>{
            if(radio.checked){
              validateStep = true
              let radioParentError = quizeActiveStep.querySelectorAll(".quize-stepper-radio")
              if(radioParentError){
                radioParentError.forEach(radioErrorQuize=>{
                  radioErrorQuize.classList.remove("error-radio")
                })
              }
            }
          })
        }else{
          validateStep = true
        }
        // validate current step end

        // move stepper to current
        if(validateStep && incomeBtn.classList.contains("stepper-next__btn")){
          quizeStepper.allowTouchMove = true
          quizeStepper.slideNext();
          quizeStepper.allowTouchMove = false

        }else if(incomeBtn.classList.contains("stepper-prev__btn")){
          quizeStepper.allowTouchMove = true
          quizeStepper.slidePrev();
          quizeStepper.allowTouchMove = false
          let radioParentError = quizeActiveStep.querySelectorAll(".quize-stepper-radio")
          if(radioParentError){
            radioParentError.forEach(radioErrorQuize=>{
              radioErrorQuize.classList.remove("error-radio")
            })
          }
        }
        // move stepper to current end
      }
    }
    //  clear error on radios on select
    let quizeStepRadioBtn = document.querySelectorAll(".quize-stepper-radio input")
    if(quizeStepRadioBtn){
      quizeStepRadioBtn.forEach(quizeRadioInput=>{
        quizeRadioInput.addEventListener("change",function(){
          if(quizeRadioInput.checked){
            let quizeStepRadioFiled = quizeRadioInput.closest(".quize-stepper-radio__list")
            if(quizeStepRadioFiled){
              let closestRadiosQuize = quizeStepRadioFiled.querySelectorAll(".quize-stepper-radio")
              closestRadiosQuize.forEach(radio=>{
                radio.classList.remove("error-radio")
              })
            }
          }
        })
      })
    }
    //  clear error on radios on select end
    // validation quize step by step end  ============================================================================

    // navigation
    let quizeStepperNext = quizeSliderContainer.querySelectorAll(".stepper-next__btn")
    if(quizeStepperNext){
      quizeStepperNext.forEach(quizeNext=>{
        quizeNext.addEventListener("click",function(){
          validateStep(quizeNext)
        })
      })
    }
    let quizeStepperPrev = document.querySelectorAll(".stepper-prev__btn")
    if(quizeStepperPrev){
      quizeStepperPrev.forEach(quizePrev=>{
        quizePrev.addEventListener("click",function(){
          validateStep(quizePrev)
        })
      })
    }
    // navigation end
  }
  // quize stepper end ===================================================


  // about slider ===================================================
  // set random delay
  let timeDelayMin = 3000
  let timeDelayMax = 7000
  let timeDelay = timeDelayMin
  setInterval(function(){
    timeDelay = Math.floor(Math.random() * (timeDelayMax - timeDelayMin)) + timeDelayMin
    if(swiperAbout){
      swiperAbout.params.autoplay.delay = timeDelay
    }
  },timeDelay)
  // set random delay end
  // swiper init
  var swiperAbout = new Swiper(".about-slider__section .about-main__slider", {
    speed: 700,
    slidesPerView:1,
    allowTouchMove: true,
    lazy: true,
    autoplay:{
      delay: timeDelay,
      disableOnInteraction: false,
    },
    effect: "fade",
    breakpoints:{
      1025:{
        allowTouchMove: false,
      }
    }
  });
  // swiper init end

  // float items
  let floatAboutItems = document.querySelectorAll(".about-float__item")
  if(floatAboutItems){
    // change slide on float click
    floatAboutItems.forEach(floatItem=>{
      floatItem.addEventListener("click",function(){
        if(floatItem.hasAttribute("data-slide")){
          swiperAbout.slideTo(parseInt(floatItem.getAttribute("data-slide")) - 1)
        }
      })
    })
    // change slide on float click end

    // change slide event
    swiperAbout.on('slideChange',function(){
      let sliderAboutLength = document.querySelectorAll(".about-main__slider .swiper-slide").length
      let NumberSlide = 0
      // change float items
      let floatChange = document.querySelector(`.about-float__item[data-slide='${swiperAbout.activeIndex + 1}']`)
      if(floatChange){
        if(floatChange.hasAttribute("data-slide")){
          let nextIndexSlide = parseInt(floatChange.getAttribute("data-slide")) + floatAboutItems.length
          if(nextIndexSlide > sliderAboutLength){
            nextIndexSlide = (sliderAboutLength - nextIndexSlide) * (-1)
          }
          let pathSlider = 'about'
          NumberSlide = parseInt(floatChange.getAttribute("data-slide"))

          // add effect fade
          floatChange.classList.add("clicked")
          setTimeout(function(){
            floatChange.classList.remove("clicked")
          },700)
          // add effect fade end

          // new photo
          let hiddenFloatNewImg = floatChange.querySelector(".new-float__photo img")
          let hiddenFloatNewSource = floatChange.querySelector(".new-float__photo source")
          hiddenFloatNewImg.src= `img/content/${pathSlider}/${pathSlider}-${nextIndexSlide}.png`
          hiddenFloatNewSource.srcset= `img/content/${pathSlider}/${pathSlider}-${nextIndexSlide}.webp`

          // old photo
          let hiddenFloatOldImg = floatChange.querySelector(".main-float__photo img")
          let hiddenFloatOldSource = floatChange.querySelector(".main-float__photo source")
          setTimeout(function(){
            hiddenFloatOldImg.src= `img/content/${pathSlider}/${pathSlider}-${nextIndexSlide}.png`
            hiddenFloatOldSource.srcset= `img/content/${pathSlider}/${pathSlider}-${nextIndexSlide}.webp`
          },600)
          floatChange.setAttribute("data-slide", nextIndexSlide)
        }
      }
    })
    // change slide end
  }
  // float items end

  // about slider end ===================================================


  // team slider ===================================================
  // swiper init
  var swiperTeam = new Swiper(".team__slider .swiper-container.global-team", {
    speed: 1000,
    slidesPerView:1,
    effect: "fade",
    autoHeight: true,
    allowTouchMove: false,
    navigation: {
      nextEl: ".team__slider .slider-button__next",
      prevEl: ".team__slider .slider-button__prev",
    },
    breakpoints:{
      1201:{
        autoHeight: false
      }
    }
  });
  let sliderTeamButtons = this.document.querySelectorAll(".team__slider .slider-button")
  if(sliderTeamButtons){
    sliderTeamButtons.forEach(slideBtn=>{
      slideBtn.addEventListener("click",function(){
        if(window.innerWidth < 701){
          let teamTrigger = document.querySelector('.header__link[data-anchor="team"]')
          let eventTrigger = new Event("click")
          if(teamTrigger){
            teamTrigger.dispatchEvent(eventTrigger)
            AOS.refresh()
          }
        }
      })
    })
  }
  // swiper init end
  // team slider end ===================================================

  // team main slider =======================================================================================
  let teamPersonalSlider = document.querySelectorAll(".team-person__slider")
  if(teamPersonalSlider){
    teamPersonalSlider.forEach(teamPhotoshow=>{
      // swiper init
      let swiperTeamMain = new Swiper(teamPhotoshow, {
        speed: 700,
        slidesPerView:1,
        allowTouchMove: true,
        // autoHeight:true,
        lazy: true,
        autoplay:{
          delay: 2500,
          disableOnInteraction: false,
        },
        effect: "fade",
        breakpoints:{
          701:{
            allowTouchMove: false,
          }
        }
      });
      // swiper init end

      // float items
      let floatTeamItems = teamPhotoshow.querySelectorAll(".team-item-float__item")
      if(floatTeamItems){
        // change slide on float click
        floatTeamItems.forEach(floatItem=>{
          floatItem.addEventListener("click",function(){
            if(floatItem.hasAttribute("data-slide")){
              swiperTeamMain.slideTo(parseInt(floatItem.getAttribute("data-slide")) - 1)
            }
          })
        })
        // change slide on float click end

        // change slide event
        swiperTeamMain.on('slideChange',function(){
          let sliderTeamLength = teamPhotoshow.querySelectorAll(".swiper-slide").length
          let NumberSlide = 0
          // change float items
          let floatChange = teamPhotoshow.querySelector(`.team-item-float__item[data-slide='${swiperTeamMain.activeIndex + 1}']`)
          if(floatChange){
            if(floatChange.hasAttribute("data-slide")){
              let nextIndexSlide = parseInt(floatChange.getAttribute("data-slide")) + floatTeamItems.length
              if(nextIndexSlide > sliderTeamLength){
                nextIndexSlide = (sliderTeamLength - nextIndexSlide) * (-1)
              }
              let pathSlider = teamPhotoshow.getAttribute("data-path")
              NumberSlide = parseInt(floatChange.getAttribute("data-slide"))

              // add effect fade
              floatChange.classList.add("clicked")
              setTimeout(function(){
                floatChange.classList.remove("clicked")
              },700)
              // add effect fade end

              // new photo
              let hiddenFloatNewImg = floatChange.querySelector(".new-float__photo img")
              let hiddenFloatNewSource = floatChange.querySelector(".new-float__photo source")
              hiddenFloatNewImg.src= `img/content/team/${pathSlider}/${pathSlider}-${nextIndexSlide}.png`
              hiddenFloatNewSource.srcset= `img/content/team/${pathSlider}/${pathSlider}-${nextIndexSlide}.webp`

              // old photo
              let hiddenFloatOldImg = floatChange.querySelector(".main-float__photo img")
              let hiddenFloatOldSource = floatChange.querySelector(".main-float__photo source")
              setTimeout(function(){
                hiddenFloatOldImg.src= `img/content/team/${pathSlider}/${pathSlider}-${nextIndexSlide}.png`
                hiddenFloatOldSource.srcset= `img/content/team/${pathSlider}/${pathSlider}-${nextIndexSlide}.webp`
              },600)
              floatChange.setAttribute("data-slide", nextIndexSlide)
            }
          }
        })
        // change slide end
      }
      // float items end
    })
  }
  // team main slider end ===================================================================================
})
